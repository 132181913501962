import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { Session, SessionParticipant } from '@Types/Session';
import { UserBadge } from '@Components/UI/UserBadge';
import { Line } from '@Components/UI/Line';
import { BackofficeContext } from '@Context/BackofficeContext';
import { Activity, ActivityHistory, FreeTextActivity, QuizzActivity, QuizzAnswer, TCSActivity } from '@Types/Activity';
import { Button } from 'primereact/button';
import styled from 'styled-components';
import { ReadOnlyEditor } from '@Components/ReadOnlyEditor';
import { groupBy, mapValues } from 'lodash';
import { UserProgressItem } from '@Types/User';
import { useReactToPrint } from 'react-to-print';

const AnswerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 5px 7px 10px;
    border: 1px solid #d0d5dd;
    margin-top: 8px;
    border-radius: 8px;
`;

export type AnswerDetailsProps = {
    onQuit: () => void;
    session: Session;
    participant: SessionParticipant;
    userProgress: UserProgressItem[];
};
export const AnswerDetails: React.FC<AnswerDetailsProps> = (props) => {
    const { modules, formations } = useContext(BackofficeContext);

    const modulesOrdered = useMemo(
        () =>
            formations
                .find((f) => f.formation_id === props.session.formation_id)
                ?.modules.map((m) => modules.find((mi) => mi.module_id === m.module_id)) || [],
        [modules, formations, props.session]
    );

    const progress = useMemo(() => {
        return mapValues(groupBy(props.userProgress, 'module_id'), (v) => {
            return Object.fromEntries(v.map((v) => [v.activity_id, v.activity])) as { [k: string]: ActivityHistory };
        });
    }, [props.userProgress]);

    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });
    const displayContent = useCallback(
        () => (
            <>
                <UserBadge user={props.participant} email={false} />
                {Object.entries(progress || {})
                    .sort(
                        ([a], [b]) =>
                            modulesOrdered.findIndex((m) => m?.module_id === a) -
                            modulesOrdered.findIndex((m) => m?.module_id === b)
                    )
                    .map(([module_id, module]) => {
                        const activities = modules.find((m) => m.module_id === module_id)?.activities || [];

                        const foundModule = modules.find((m) => m.module_id === module_id);

                        return (
                            <div key={module_id}>
                                <Line height={1} className="bg-gray-200 my-4" />
                                <div className="he-paragraph--medium my-4">Module - {foundModule?.title}</div>
                                {Object.entries(module ?? {})
                                    .sort(
                                        ([a], [b]) =>
                                            activities.findIndex((ac) => ac?.activity_id === a) -
                                            activities.findIndex((ac) => ac?.activity_id === b)
                                    )
                                    .map(([activity_id, answerHistory]) => {
                                        const activity: Activity | undefined = foundModule?.activities.find(
                                            (a) => a.activity_id === activity_id
                                        );

                                        if (!activity) return <React.Fragment></React.Fragment>;

                                        return activity?.type &&
                                            answerHistory &&
                                            ['mcq', 'ucq', 'tcs', 'freetext'].includes(activity.type) ? (
                                            <div key={activity_id}>
                                                <div className="he-paragraph--small-bold mb-2">
                                                    Activité - {activity?.title}{' '}
                                                </div>
                                                <div className="mb-3">
                                                    {(activity.type === 'mcq' || activity.type === 'ucq') && (
                                                        <div className="quill-custom">
                                                            <ReadOnlyEditor
                                                                content={(activity as QuizzActivity).question || ''}
                                                            />
                                                            {(answerHistory.answers as QuizzAnswer).map((r, i) => {
                                                                return (
                                                                    <AnswerBox key={i}>
                                                                        {r + 1} -{' '}
                                                                        {answerHistory.answers
                                                                            ? (activity as QuizzActivity).answers[
                                                                                  r as 1
                                                                              ].text_or_image
                                                                            : ' - '}
                                                                    </AnswerBox>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                    {activity.type === 'tcs' && (
                                                        <div className="quill-custom">
                                                            <ReadOnlyEditor
                                                                content={(activity as TCSActivity).description}
                                                            />
                                                            {(activity as TCSActivity).hypothesis.map((h, i) => {
                                                                const labels = {
                                                                    '0': 'Fortement négatif',
                                                                    '1': 'Négatif',
                                                                    '2': 'Neutre',
                                                                    '3': 'Positif',
                                                                    '4': 'Fortement positif',
                                                                    ...(activity as TCSActivity).labels,
                                                                };
                                                                return (
                                                                    <AnswerBox key={i}>
                                                                        {i + 1} -{' '}
                                                                        {answerHistory.answers
                                                                            ? labels[answerHistory.answers[i as 1] as 1]
                                                                            : 0}
                                                                    </AnswerBox>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                    {activity.type === 'freetext' && (
                                                        <div className="quill-custom">
                                                            <ReadOnlyEditor
                                                                content={(activity as FreeTextActivity).question}
                                                            />
                                                            <AnswerBox>
                                                                {answerHistory.answer ? answerHistory.answer : ' - '}
                                                            </AnswerBox>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        );
                                    })}
                            </div>
                        );
                    })}
            </>
        ),
        [props.participant, progress, modulesOrdered]
    );

    return (
        <>
            <PrintableDiv ref={ref}>{displayContent()}</PrintableDiv>
            <div className="AnswerDetails relative w-full">
                <div
                    className="he-header--h3 mb-5 flex align-items-center justify-content-between fixed z-5"
                    style={{ background: 'white', width: 530 }}
                >
                    <div>
                        Détail des réponses d’un apprenant
                        <i className="cursor-pointer ml-3 pi pi-print" onClick={handlePrint} />
                    </div>
                    <div className="flex justify-content-end ml-4">
                        <Button
                            label="Retour"
                            className="he-button--secondary-variant-nfb--md"
                            onClick={props.onQuit}
                        />
                    </div>
                </div>
                <div className="pt-7 h-full">{displayContent()}</div>
            </div>
        </>
    );
};

const PrintableDiv = styled.div`
    // Invisible except on print

    position: absolute;
    z-index: 9999;
    width: 100%;
    height: max-content;
    background: white;
    padding: 20px;
    top: 0;
    left: 0;

    display: none;

    @media print {
        display: block;
    }
`;
