import React from "react";
import {Avatar} from "primereact/avatar";

export type UserBadgeProps = {
	user: {
		firstname: string,
		lastname: string,
		email?: string
	},
	email?: boolean,
	fullName?: boolean,
	badge?: boolean
}
export const UserBadge: React.FC<UserBadgeProps> = props => {

	return (
		<div className="flex align-items-center">
			<div className="mr-2">
				<Avatar label={`${props.user.firstname[0]}${props.user.lastname[0]}`.toUpperCase()} shape="circle"/>
			</div>
			<div className="flex flex-column">
				<div className="he-paragraph--regular gray-600" draggable="false">{props.user.firstname} {props.user.lastname}</div>
				{props.email !== false && <div className="he-paragraph--small gray-500 mt-1">{props.user.email}</div>}
			</div>
		</div>
	)
}
