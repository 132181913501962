import React, { useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import { Session } from '@Types/Session';
import { usePromise } from '@Hooks/promise';
import { useApi } from '@Hooks/api';
import { CustomInput } from '@Components/CustomInput';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { CustomFormDropdown } from '@Components/CustomFormDropdown';
import { BackofficeContext } from '@Context/BackofficeContext';
import { DateTime } from 'luxon';
import { useUser } from '@Hooks/firebase';
import { Formation } from '@Types/Formation';
import { SessionContext } from '@Context/SessionContext';
import XLSX from 'sheetjs-style';
import { downloadUint8 } from '@Utils/download.utils';
import { Module } from '@Types/Module';

export type SessionSettingsProps = {
    session: Session;
    formation: Formation;
};
export const SessionSettings: React.FC<SessionSettingsProps> = (props) => {
    const api = useApi();
    const { formations, modules } = useContext(BackofficeContext);
    const { participants, usersProgress } = useContext(SessionContext);

    const { meta } = useUser(true);

    const [updateSession, loading] = usePromise(
        async (session: Session) => {
            const res = await api.session_call_update({
                session: session,
            });

            if (res.result !== 'ok') throw new Error(res.result);
        },
        {
            pending: 'Mise à jour en cours',
            success: 'Session mise à jour !',
        }
    );

    const sessionFormik = useFormik({
        initialValues: props.session,
        enableReinitialize: true,
        onSubmit: updateSession,
    });

    const isPassed = useMemo(() => {
        return DateTime.fromISO(sessionFormik.values.start_date).toMillis() <= Date.now();
    }, [sessionFormik.values.start_date]);

    const onExtractAnswers = async () => {
        const sheets: {
            unit_id: string;
            title: string;
            data: string[][];
        }[] = props.session.unitsConfig.map((u, i) => {
            return {
                unit_id: u.unit_id,
                title: `Unité ${i + 1}`,
                data: [],
            };
        });

        for (const sheet of sheets) {
            const unit = props.formation.units.find((u) => u.unit_id === sheet.unit_id);
            if (!unit) continue;
            const modulesConfig = props.formation.modules.filter((m) => m.visible).map((m) => m.module_id);
            const unitModules = unit.modules_ids
                .map((m) => modules.find((mod) => mod.module_id === m))
                .filter((m) => m !== undefined && modulesConfig.includes(m.module_id)) as Module[];

            for (const module of unitModules) {
                sheet.data.push([], [module.title]);
                const moduleActivities = module.activities;

                sheet.data.push(['Utilisateur', ...moduleActivities.map((a) => a.title)]);

                for (const participant of participants) {
                    const userProgress = usersProgress[participant.participant_id];
                    if (!userProgress) continue;

                    const userModuleProgress = userProgress.filter((up) => up.module_id === module.module_id);
                    if (!userModuleProgress) continue;

                    const userAnswers = moduleActivities
                        .filter((a) => a.type === 'freetext')
                        .map((a) => {
                            const ah = userModuleProgress.find((up) => up.activity_id === a.activity_id);
                            if (!ah) return '-';

                            if (a.type === 'freetext') {
                                return ah.activity.answer ?? '';
                            } else {
                                return 'Non supporté';
                            }
                        });

                    sheet.data.push([participant.firstname + ' ' + participant.lastname, ...userAnswers]);
                }
            }
        }

        const buff = XLSX.write(
            {
                Sheets: Object.fromEntries(sheets.map((s) => [s.title, XLSX.utils.aoa_to_sheet(s.data)])),
                SheetNames: sheets.map((s) => s.title),
            },
            {
                bookType: 'xlsx',
                type: 'array',
            }
        );

        return downloadUint8(
            new Uint8Array(buff),
            `Réponses-session-${props.session.session_custom_id}-${props.formation.title}.xlsx`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
    };

    return (
        <div className="SessionSettings w-6">
            <div className="he-header--h3 gray-900">Informations générales</div>
            <div className="mt-4">
                <CustomInput
                    label="Numéro de la session"
                    placeholder="Numéro de la session"
                    field="session_custom_id"
                    disabled={!['admin', 'author'].includes(meta.role)}
                    formik={sessionFormik}
                />
            </div>
            <div className="mt-3">
                <CustomFormDropdown
                    options={formations.map((f) => ({ label: f.title, value: f.formation_id }))}
                    label="Formation"
                    field="formation_id"
                    disabled={isPassed}
                    placeholder={'Sélectionner une formation'}
                    filter
                    filterBy="label"
                    formik={sessionFormik}
                />
            </div>
            <div className="flex mt-3">
                <div className="flex-1 mr-4">
                    <div className="he-paragraph--regular gray-500">Date de début</div>
                    <Calendar
                        className="mt-2 w-full"
                        placeholder={'jj/mm/aaaa'}
                        disabled={isPassed}
                        dateFormat={'dd/mm/yy'}
                        value={DateTime.fromISO(sessionFormik.values.start_date).toJSDate()}
                        onChange={(e) =>
                            e.value &&
                            sessionFormik.setFieldValue('start_date', DateTime.fromJSDate(e.value).toISO(), false)
                        }
                    />
                </div>
                <div className="flex-1">
                    <div className="he-paragraph--regular gray-500">Date de fin</div>
                    <Calendar
                        className="mt-2 w-full"
                        minDate={DateTime.fromISO(sessionFormik.values.start_date).toJSDate()}
                        placeholder={'jj/mm/aaaa'}
                        dateFormat={'dd/mm/yy'}
                        value={DateTime.fromISO(sessionFormik.values.end_date).toJSDate()}
                        onChange={(e) =>
                            e.value &&
                            sessionFormik.setFieldValue('end_date', DateTime.fromJSDate(e.value).toISO(), false)
                        }
                    />
                </div>
            </div>
            <Button
                className="he-button--primary--md mt-3"
                label={'Enregistrer'}
                icon="pi pi-save"
                loading={loading}
                onClick={sessionFormik.submitForm}
            />
            {meta.email === 'christopher.martin@mobileo.tech' && (
                <Button
                    label="Extraction des réponses"
                    className="he-button--secondary--md mt-3"
                    onClick={() => {
                        onExtractAnswers();
                    }}
                />
            )}
        </div>
    );
};
