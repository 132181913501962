import React, { useCallback, useContext, useState } from 'react';
import { Button } from 'primereact/button';
import styled from 'styled-components';
import { usePromise } from '@Hooks/promise';
import { createUserSchema } from '@Schemas/user.schema';
import { ValidationError } from 'yup';
import { User } from '@Types/User';
import { VirtualScroller } from 'primereact/virtualscroller';
import { useApi } from '@Hooks/api';

import { parse } from 'csv-parse/sync';
import { UsersContext } from '@Context/UsersContext';
import { CustomDialog } from '@Components/CustomDialog';

const UserListContainer = styled.textarea`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    height: min-content;
    text-align: center;
    resize: none;
    border-radius: 8px;
    height: 50px;

    &::placeholder {
        display: block;
        text-align: center;
    }
`;

const UserListDivContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 14px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    width: 100%;
`;

export type ImportUsersProps = {
    onQuit: () => void;
};
export const ImportUsers: React.FC<ImportUsersProps> = (props) => {
    const [userList, setUserList] = useState<User[]>();
    const [badUsers, setBadUsers] = useState<[User, User][]>([]);
    const [showBadUsers, setShowBadUsers] = useState(false);

    const api = useApi();
    const { users } = useContext(UsersContext);

    const [importUserList, loading] = usePromise(
        async (users: User[]) => {
            const res = await api.user_call_import({
                users: users,
            });

            if (res.result !== 'ok') throw new Error(res.result);
        },
        {
            pending: 'importation des utilisateurs en cours...',
            success: 'Utilisateurs importés !',
        }
    );

    const [forceImportUserList, forceLoading] = usePromise(
        async (users: User[], badUsers: [new: User, old: User][]) => {
            const normalUsers = users.filter(
                (user) => !badUsers.find(([_, u]) => u.email === user.email && u.RPPS !== user.RPPS)
            );

            if (normalUsers.length > 0) {
                const firstRes = await api.user_call_import({
                    users: normalUsers,
                });

                if (firstRes.result !== 'ok') throw new Error(firstRes.result);
            }

            if (badUsers.length === 0) return;

            const secondRes = await api.user_call_forceImport({
                users: badUsers.map(([newUser, oldUser]) => ({ ...newUser, user_id: oldUser.user_id })),
            });

            if (secondRes.result !== 'ok') throw new Error(secondRes.result);
        },
        {
            pending: 'importation des utilisateurs en cours...',
            success: 'Utilisateurs importés !',
        }
    );
    const checkRppsChanges = useCallback(
        (userList: User[]) => {
            const allErrors: [User, User][] = [];

            for (const user of userList) {
                const found = users.find((u) => u.email === user.email && u.RPPS !== user.RPPS);
                if (found) {
                    allErrors.push([user, found] as const);
                }
            }
            setBadUsers(allErrors);
        },
        [users]
    );
    const [errors, setErrors] = useState<string[][]>([]);

    const parseGooglePaste = (raw: string) => {
        const lines: string[] = parse(raw, {
            delimiter: '\t',
            trim: true,
        });

        const formatted: User[] = lines.map((line) => {
            const [lastname, firstname, RPPS, email, address, phone, job, exercise_mode] = line;
            return {
                role: 'user',
                user_id: '',
                verified: false,
                firstname,
                lastname,
                RPPS,
                email,
                address,
                job,
                phone,
                exercise_mode,
                accept_mailings: 3,
            };
        });

        const allErrors = [];

        checkRppsChanges(formatted);

        for (const [i, user] of formatted.entries()) {
            console.log(user);
            try {
                createUserSchema.validateSync(user, {
                    abortEarly: false,
                    stripUnknown: true,
                });
            } catch (err: unknown) {
                if (err instanceof ValidationError) {
                    allErrors.push(err.inner.map((data) => `ligne ${i + 1} - ${data.path} : ${data.errors.join(',')}`));
                }
            }
        }

        setErrors(allErrors);
        setUserList(formatted);
    };

    const loadClipboardData = async () => {
        const content = await navigator.clipboard.readText();
        parseGooglePaste(content);
    };

    return (
        <div className="w-full h-full">
            <div className="he-header--h3 inter mb-4">Importer des utilisateurs</div>
            <div className="he-paragraph--regular gray-500 mb-3">Liste des utilisateurs</div>
            {errors.length === 0 && !userList && (
                <>
                    <div className="flex justify-content-center align-items-center flex-column">
                        <Button
                            label="Cliquer pour coller la liste d' utilisateurs"
                            className="he-button--secondary-variant--md mr-2 px-5 w-max"
                            onClick={loadClipboardData}
                        />
                        <div className="he-header--h3 gray-400 my-2">ou</div>
                    </div>
                    <UserListContainer
                        className="w-full"
                        placeholder="Coller ici la liste d’utilisateur provenant de Google Sheet"
                        onPaste={(e) => parseGooglePaste(e.clipboardData.getData('text/plain'))}
                    />
                </>
            )}
            {errors.length === 0 && userList && (
                <UserListDivContainer>
                    <VirtualScroller
                        items={userList}
                        itemSize={[28]}
                        scrollHeight={'400px'}
                        itemTemplate={(user, options) => (
                            <div
                                className={`flex align-items-center white-space-nowrap he-paragraph--regular gray-600 py-2 px-2 ${
                                    options.index % 2 === 0 ? 'bg-gray-100' : ''
                                } `}
                                style={{ height: 28 }}
                            >
                                <div className="mr-2" style={{ minWidth: '200px' }}>
                                    {user.lastname}
                                </div>
                                <div className="mr-2" style={{ minWidth: '200px' }}>
                                    {user.firstname}
                                </div>
                                <div className="mr-2" style={{ minWidth: '100px' }}>
                                    {user.RPPS}
                                </div>
                                <div className="mr-2" style={{ minWidth: '300px' }}>
                                    {user.email}
                                </div>
                                <div className="mr-2" style={{ minWidth: '450px' }}>
                                    {user.address}
                                </div>
                                <div className="mr-2" style={{ minWidth: '100px' }}>
                                    {user.phone}
                                </div>
                                <div className="mr-2" style={{ minWidth: '100px' }}>
                                    {user.job}
                                </div>
                                <div className="mr-2" style={{ minWidth: '100px' }}>
                                    {user.exercise_mode}
                                </div>
                            </div>
                        )}
                    />
                </UserListDivContainer>
            )}
            {errors.length > 0 && (
                <div>
                    <div></div>
                    {errors.map((errorGroup, i) =>
                        errorGroup.map((error, j) => (
                            <div className="he-paragraph--small color-red" key={i + '' + j}>
                                {error}
                            </div>
                        ))
                    )}
                </div>
            )}

            {showBadUsers && (
                <CustomDialog onHide={() => setShowBadUsers(false)} closable={false}>
                    <div className="w-full h-full">
                        <div className="he-header--h3 inter mb-4">Conflit de RPPS</div>
                        <div className="he-paragraph--regular gray-500 mb-3">
                            Les utilisateurs suivants ont déjà été importés avec un RPPS différent.
                            <br /> Voulez-vous les mettre à jour ?
                        </div>

                        <VirtualScroller
                            items={badUsers}
                            itemSize={28}
                            scrollHeight={'400px'}
                            itemTemplate={([user, found], options) => (
                                <div
                                    className={`flex align-items-center white-space-nowrap he-paragraph--regular gray-600 py-2 px-2 ${
                                        options.index % 2 === 0 ? 'bg-gray-100' : ''
                                    } `}
                                    style={{ height: 28 }}
                                >
                                    <div className="mr-2" style={{ minWidth: '300px' }}>
                                        {user.email}
                                    </div>
                                    <div className="mr-2" style={{ minWidth: '200px' }}>
                                        {user.RPPS} <i className="pi pi-arrow-right mx-2" /> {found.RPPS}
                                    </div>
                                </div>
                            )}
                        />
                        <div className="flex justify-content-end align-items-center mt-4">
                            <Button
                                className="he-button--secondary-nfb--md mr-2"
                                label="Annuler"
                                loading={forceLoading}
                                onClick={() => setShowBadUsers(false)}
                            />
                            <Button
                                loading={forceLoading}
                                className="he-button--danger--md"
                                disabled={errors.length > 0 || !userList}
                                label={'Appliquer les modifications'}
                                icon={'pi pi-upload'}
                                onClick={() =>
                                    userList && badUsers.length > 0 && forceImportUserList(userList, badUsers)
                                }
                            />
                        </div>
                    </div>
                </CustomDialog>
            )}
            <div className="flex justify-content-end align-items-center mt-4">
                {errors.length === 0 && userList && (
                    <div className="mr-auto he-paragraph--regular primary-100">{userList.length} lignes importées</div>
                )}
                <Button
                    className="he-button--secondary-nfb--md mr-2"
                    label="Annuler"
                    loading={loading}
                    onClick={props.onQuit}
                />
                {badUsers.length > 0 ? (
                    <Button
                        className="he-button--secondary-variant--md mr-2"
                        icon={'pi pi-exclamation-triangle'}
                        label="Voir les conflits"
                        iconPos="right"
                        onClick={() => setShowBadUsers(true)}
                    />
                ) : (
                    <Button
                        loading={loading}
                        className="he-button--primary--md"
                        disabled={errors.length > 0 || !userList}
                        label={'Importer les utilisateurs'}
                        icon={'pi pi-upload'}
                        onClick={() => userList && importUserList(userList)}
                    />
                )}
            </div>
        </div>
    );
};
