import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { useToast } from '@Hooks/toast';
import { uploadBigResource } from '@Utils/import.utils';
import { Resource } from '@Types/Resource';
import { firestore } from '@Utils/config/firebase';
import type { UploadTask } from 'firebase/storage';
import { ProgressBar } from 'primereact/progressbar';

export type UploadButtonProps = {
    buttonClassName?: string;
    className?: string;
    label: string;
    file_id?: string | null;
    icon?: string;
    accept?: string[];
    iconPos?: 'top' | 'bottom' | 'left' | 'right';
    loading?: boolean;
    onUploaded?: (resource_id: string) => void;
    onRemove?: (file_id?: string | null) => void;
};
export const UploadBigFileButton: React.FC<UploadButtonProps> = (props) => {
    const [filePreview, setFilePreview] = useState<boolean>(false);

    const ref = useRef<HTMLInputElement>(null);

    const { error, success, info } = useToast();

    const [currentFile, setCurrentFile] = useState<File>();

    const [loadedFile, setLoadedFile] = useState<Resource>();
    const [uploading, setUploading] = useState<boolean>(false);

    const uploadTask = useRef<UploadTask | null>(null);
    const [progress, setProgress] = useState<number>(0);

    const reset = () => {
        setFilePreview(false);
        setCurrentFile(undefined);
        setLoadedFile(undefined);
        ref.current?.value && (ref.current.value = '');
        setProgress(0);
        uploadTask.current = null;
    };

    useEffect(() => {
        if (props.file_id) {
            firestore
                .collection<Resource>('resources')
                .get(props.file_id)
                .then((res) => {
                    setLoadedFile(res);
                });
        }
        return () => {
            reset();
        };
    }, [props.file_id]);

    const onRemove = () => {
        reset();
        props.onRemove?.(props.file_id);
    };

    const onSelect = (files: FileList) => {
        const file = files[0];
        if (props.accept && !props.accept.includes(file.type)) {
            error(
                `Veuillez sélectionner un fichier parmis les suivants ${props.accept
                    .map((e) => e.split('/')[1])
                    .join(', ')}`
            );
            return;
        }
        setCurrentFile(file);
        setUploading(true);
        info('Envoi du fichier en cours...');
        uploadTask.current = uploadBigResource(file, {
            onStateChange: (snapshot) => {
                setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
            onError: (err) => {
                setUploading(false);
                reset();
                error("Erreur lors de l'envoi du fichier: " + err.message);
            },
            onComplete: (id) => {
                setUploading(false);
                success('Fichier envoyé avec succès');
                setFilePreview(true);
                props.onUploaded?.(id);
            },
        });
    };

    const onCancelUpload = () => {
        uploadTask.current?.cancel();
        reset();
    };

    return (
        <div className={props.className}>
            <input
                ref={ref}
                type={'file'}
                hidden
                accept={props.accept?.join(', ') || '*'}
                onChange={(e) => {
                    e.target.files && e.target.files[0] && onSelect(e.target.files);
                }}
            />
            {(filePreview || loadedFile) && (
                <div className="flex align-items-center">
                    <div className="he-button--primary-nfb--xs">
                        <i className="pi pi-file-o" />
                        {currentFile?.name || loadedFile?.resource_name}
                    </div>
                    <i className="pi pi-times-circle color-red ml-3 cursor-pointer" onClick={onRemove} />
                </div>
            )}
            {!loadedFile && !currentFile && !uploading && (
                <Button
                    className={props.buttonClassName}
                    onClick={() => ref.current?.click()}
                    label={props.label}
                    icon={props.icon}
                    iconPos={props.iconPos}
                    disabled={uploading || props.loading}
                    loading={uploading || props.loading}
                />
            )}
            {uploading && (
                <div className="flex flex-column gap-1 w-full">
                    <Button
                        className="he-button--secondary-variant--xs w-full"
                        onClick={onCancelUpload}
                        iconPos={props.iconPos}
                    >
                        <i className="pi pi-spin pi-spinner" />
                        Annuler l'envoi
                    </Button>
                    <ProgressBar style={{ height: '6px' }} showValue={false} value={progress} />
                </div>
            )}
        </div>
    );
};
